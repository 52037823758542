import React from 'react'
import './SelectInput.css'
import Form from 'react-bootstrap/Form'

class SelectInput extends React.Component {

	render() {
		return (
			<Form.Group className="select-input">
				<Form.Label>{this.props.title}</Form.Label>
				<Form.Control type="numtextber" value={this.props.value} className={this.props.state}
					onInput={
						ev => {
							this.props.selectHandler(ev.target.value, false)
						}
					}
					onChange={() => { }} />
				{
					this.props.opts &&
					<select className={(this.props.opts.length > 1 ? 'visible' : 'hidden') + ' form-control'} size={Math.min(this.props.opts.length, 10)}>
						{
							this.props.opts.map(
								(item, key) => <option key={key}
									onClick={
										ev => this.props.selectHandler(ev.target.value, true)}>{item}</option>
							)
						}
					</select>
				}
			</Form.Group>
		)
	}
}

export default SelectInput