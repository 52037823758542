import React from 'react';
import Form from 'react-bootstrap/Form';

class Development extends React.Component {

	render() {
		return (
			<Form>
				<Form.Group className="development">
					<ul>
						<li>Datenquelle der Geodaten: <a href="https://download.geofabrik.de/europe/germany.html" target="_blank" rel="noopener noreferrer">https://download.geofabrik.de/europe/germany.html</a></li>
						<li>Basisadresse des Demo GUIs: <a href="/index.html" target="_blank">/index.html</a></li>
						<li>Basisadresse des REST Services: <a href="/" target="_blank">/</a></li>
						<li>Swagger REST API Doc: <a href="/swagger-ui.html" target="_blank">/swagger-ui.html</a></li>
						<li>Datenbank: PostGIS</li>
						<li>Sourcecode und Installationsanleitung: <a href="https://gitlab.com/kasch65/addressservice" target="_blank" rel="noopener noreferrer">https://gitlab.com/kasch65/addressservice</a></li>
					</ul>
					Kontakt: <a href="mailto:kai.schaefer@arithnea.de">kai.schaefer@arithnea.de</a>
				</Form.Group>
			</Form>
		);
	}

}

export default Development;