import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Address from './Address'
import Development from './Development'

class App extends React.Component {

	render() {
		return (
			<div className="App">
				<Tabs defaultActiveKey="Adresseingabe">
					<Tab eventKey="Adresseingabe" title="Adresseingabe">
						<Address />
					</Tab>
					<Tab eventKey="Development" title="Entwicklung">
						<Development />
					</Tab>
				</Tabs>
			</div>
		)
	}

}

export default App
