import React from 'react'
import SelectInput from './SelectInput'
import errWav from './img/err.wav'
import okWav from './img/ok.wav'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Form from 'react-bootstrap/Form'

class Address extends React.Component {

	constructor(props) {
		super(props)
		this.controller = new AbortController()
		this.signal = this.controller.signal
		this.waiting = false

		this.state = {
			opts1: [],
			opts2: [],
			opts3: [],
			state: '',
			address: {
				zip: { zip: '' },
				city: { name: '' },
				street: { name: '' }
			}
		}

		this.sndOk = new Audio(okWav) // buffers automatically when created
		this.sndErr = new Audio(errWav) // buffers automatically when created

	}

	componentDidMount() {
		this._checkAndRecalc()
	}

	render() {
		return (
			<Form id="addess">
				<Form.Row>
					<SelectInput
						title="Zip"
						opts={this.state.opts1}
						value={this.state.address.zip.zip}
						state={this.state.state}
						selectHandler={
							(value, selected) => {
								console.debug('value, selected: ', value, selected)
								this.setState({address: this._cloneAndUpdateAddress(this.state.address, obj => obj.zip.zip = value)})
							}
						} />
					<SelectInput
						title="City"
						opts={this.state.opts2}
						value={this.state.address.city.name}
						state={this.state.state}
						selectHandler={
							(value, selected) => {
								console.debug('value, selected: ', value, selected)
								this.setState({address: this._cloneAndUpdateAddress(this.state.address, obj => obj.city.name = value)})
							}
						} />
					<SelectInput
						title="Street"
						opts={this.state.opts3}
						value={this.state.address.street.name}
						state={this.state.state}
						selectHandler={
							(value, selected) => {
								console.debug('value, selected: ', value, selected)
								this.setState({address: this._cloneAndUpdateAddress(this.state.address, obj => obj.street.name = value)})
							}
						} />
					<Form.Group>
						<Form.Label>&nbsp;</Form.Label>
						<ButtonToolbar>
							<Button variant="primary" onClick={() => this._reset()}>x</Button>

							{
								this.state.state === 'valid' &&
								<a href={'https://www.openstreetmap.org/way/' + this.state.address.street.id.split(',')[0]} id="karte" rel="noopener noreferrer" target="_blank">
									<Button variant="primary">Karte</Button>
								</a>
							}
							{
								this.state.state !== 'valid' &&
								<Button variant="primary" disabled>Karte</Button>
							}

						</ButtonToolbar>
					</Form.Group>
				</Form.Row>
			</Form>
		)
	}

	componentDidUpdate(prevProps, prevState) {
		// Count unique fields before and after input
		let curCnt = 0
		if (this.state.opts1.length === 1) {
			++curCnt
		}
		if (this.state.opts2.length === 1) {
			++curCnt
		}
		if (this.state.opts3.length === 1) {
			++curCnt
		}
		let prevCnt = 0
		if (prevState.opts1.length === 1) {
			++prevCnt
		}
		if (prevState.opts2.length === 1) {
			++prevCnt
		}
		if (prevState.opts3.length === 1) {
			++prevCnt
		}
		if (curCnt > prevCnt) {
			// Better match
			this.sndOk.play()
		}
		if (this.state.state !== prevState && this.state.state === 'void') {
			// No match
			this.sndErr.play()
		}
		// New addres in state
		if (!this._addressesEqual(prevState.address, this.state.address)) {
			this._checkAndRecalc()
		}
	}

	_checkAndRecalc() {
		// Interrupt pending request
		if (this.waiting) {
			// fetch does nothing after it has been interrupted once :-(
			/*if (!this.signal.aborted) {
				console.log('Stopping...')
				this._stopFetch()
				console.log('Stopped.')
			}*/
			console.debug('Waiting...')
			setTimeout(() => this._checkAndRecalc(), 50)
			return
		}
		
		console.debug('Checking: ', this.state.address)
		this.setState({ state: 'waiting' })

		const filterLength = this.state.address.zip.zip.length + this.state.address.city.name.length + this.state.address.street.name.length
		if (filterLength < 5) {
			this.setState({
				opts1: [],
				opts2: [],
				opts3: [],
				state: ''
			})
			return
		}

		this._getAddresses(this.state.address, results => {
			//const results = this._getAddresses(this.state.address)
			const opts1 = []
			const opts2 = []
			const opts3 = []
			results.forEach(address => {
				if (!opts1.includes(address.zip.zip)) {
					opts1.push(address.zip.zip)
				}
				if (!opts2.includes(address.city.name)) {
					opts2.push(address.city.name)
				}
				if (!opts3.includes(address.street.name)) {
					opts3.push(address.street.name)
				}
			})
			let state = ''
			let zip = this.state.address.zip.zip
			let city = this.state.address.city.name
			let street = this.state.address.street.name
			let streetId
			if (results.length === 0) {
				state = 'void'
			}
			else {
				if (opts1.length === 1) {
					zip = opts1[0]
				}
				if (opts2.length === 1) {
					city = opts2[0]
				}
				if (opts3.length === 1) {
					street = opts3[0]
				}
				if (results.length === 1) {
					state = 'valid'
					streetId = results[0].street.id
				}
			}
			this.setState({
				opts1: opts1,
				opts2: opts2,
				opts3: opts3,
				state: state,
				address: this._cloneAndUpdateAddress(this.state.address, clone => {
					clone.zip.zip = zip
					clone.city.name = city
					clone.street.name = street
					clone.street.id = streetId
				})
			})
			/*this.setState(this._valueUpdate(this.state.address, obj => obj.zip.zip = zip))
			this.setState(this._valueUpdate(this.state.address, obj => obj.city.name = city))
			this.setState(this._valueUpdate(this.state.address, obj => obj.street.name = street))*/
		})
	}

	_getAddresses(filter, callback) {
		this.waiting = true

		// Fetch new
		const request = new Request('/list?pageSize=1000&page=1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8'
			},
			body: JSON.stringify(filter),
			signal: this.signal
		})
		fetch(request).then(response => {
			// Response received
			console.debug('Response received')
			if (!response.ok) {
				throw new Error('Fetch error!')
			}
			response.json().then(json => {
				// Response parsed
				console.debug(JSON.stringify(json))
				console.info('Number of results: ', json.length)
				if (callback) {
					callback(json)
				}
				this.waiting = false
			}).catch(err => {
				this.waiting = false
				console.error('Failed to parse JSON response!', err)
			})
		}).catch(err => {
			if (err.name === 'AbortError') { // handle abort()
				console.info('Request stopped: ', err)
				// Refresh abort controller
				this.controller = new AbortController()
				this.signal = this.controller.signal
				console.log('New singal: ', this.signal)
				this.waiting = false
			} else {
				console.warn('Warning: ', err)
				this.waiting = false
			}
		})
		console.debug('fetching request...: ', request)
	}

	_valueUpdate(stateObj, updater) {
		// https://stackoverflow.com/questions/43040721/how-to-update-nested-state-properties-in-react
		const clone = { ...stateObj }
		updater(clone)
		return { clone }
	}

	_cloneAndUpdateAddress(original, updater) {
		const clone = {
			zip: { zip: original.zip.zip },
			city: { name: original.city.name },
			street: { 
				id: original.street.id,
				name: original.street.name
			}
		}
		if (updater) {
			updater(clone)
		}
		return clone
	}

	_addressesEqual(add1, add2) {
		if (add1.zip.zip !== add2.zip.zip) {
			return false
		}
		if (add1.city.name !== add2.city.name) {
			return false
		}
		if (add1.street.name !== add2.street.name) {
			return false
		}
		if (add1.street.id !== add2.street.id) {
			return false
		}
		return true
	}


	_stopFetch() {
		this.controller.abort()
	}

	_reset() {
		const addressClone = { ...this.state.address }
		addressClone.zip.zip = ''
		addressClone.city.name = ''
		addressClone.street.name = ''
		addressClone.street.id = null
		this.setState({
			opts1: [],
			opts2: [],
			opts3: [],
			state: '',
			address: addressClone
		})
	}

}

export default Address
